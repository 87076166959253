// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-article-js": () => import("./../src/templates/article/article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-method-method-js": () => import("./../src/templates/method/method.js" /* webpackChunkName: "component---src-templates-method-method-js" */),
  "component---src-templates-challenge-challenge-js": () => import("./../src/templates/challenge/challenge.js" /* webpackChunkName: "component---src-templates-challenge-challenge-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-challenge-calendar-js": () => import("./../src/pages/challenge-calendar.js" /* webpackChunkName: "component---src-pages-challenge-calendar-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-methods-js": () => import("./../src/pages/methods.js" /* webpackChunkName: "component---src-pages-methods-js" */),
  "component---src-pages-voices-js": () => import("./../src/pages/voices.js" /* webpackChunkName: "component---src-pages-voices-js" */)
}

